.header {
  background-color:#fff;
  &-top {
    height: 90px;
  }

  &-brand {
    display: block;
    width: 166px;
    height: 27px;

    img {
      max-width: 100%;
    }
  }

  &-search {
    margin-right: 45px;
    border: none;
    background: none;
    color: $primary;
    outline: none;

    &:focus,
    &:active {
      outline: none;
    }
  }

  &-menu {
    display: inline-block;
    border: 1px solid $primary;
    border-radius: $border-radius;
    padding: 12px 11px 10px;
    line-height: 1;

    &:hover {
      color: $light;
      background-color: $primary;
    }

    &.active {
      .icon-menu {
        &:before {
          content: unicode($icon-menu-close);
        }
      }
    }
  }

  .icon-search {
    font-size: 18px;
  }

  .icon-menu {
    font-size: 15px;
  }
}
