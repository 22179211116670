.main-menu-mobile {
    background-color: $primary;
    color: #fff;
    padding-top: 10px;

    width: 100%;
    min-height: 100vh;

    @include position(absolute, 0 0 auto 0);
    z-index: -1;

    margin-top: 70px;

    opacity: 0;
    transition: opacity 0.2s ease-out;

    &.active {
        opacity: 1;
        z-index: 1000;
    }

    a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        letter-spacing: 1px;

        &:hover {
            background-color: darken($primary, 10%);
        }
    }

    li {
        height: 40px;
        vertical-align: middle;
        line-height: 1.5;

        a {
            display: block;
            width: 100%;
            height: 100%;

            padding-top: (40px - 16px * 1.5) / 2;
        }
    }

    .main-links {
        font-weight: 700;
        text-transform: uppercase;

        li {
            height: 50px;

            a {
                padding-top: (50px - 16px * 1.5) / 2;
            }
        }
    }

    .social-menu {
        margin: 30px 0;

        i {
            font-size: 24px;
            vertical-align: middle;
        }

        .icon-fb {
            font-size: 28px;
        }

        li {
            display: inline-block;

            &:nth-child(2) {
                // FIXME: Az bude treti ikonka (Google Plus | Instagram), tak margin z obou stran
                margin: 0 0 0 46px;
            }

            a:hover {
                background: none;
            }
        }
    }

    &-footer {
        font-size: 14px;
        margin-bottom: 20px;
    }
}
