$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px
);

$primary: #f7941e;
$dark: #3a3a3a;

$light: #f3f3f3;

$green: #2af598;
$purple: #e71c8c;

$body-bg: white;

$detail-light: #b1b0b0;

$link-decoration: none;
$link-hover-decoration: underline;

$font-family-sans-serif: 'Nunito', sans-serif;

$border-radius: 4px;

$nav-link-padding-y: 0.5625rem;

$card-border-radius: 0;
$card-border-width: 0;
$card-spacer-x: 1.8125rem;
$card-spacer-y: 1.5rem;

$btn-padding-x: 2.875rem;
$btn-padding-y: 0.5625rem;
$btn-border-width: 2px;
$btn-font-weight: 700;

$default-transition: 0.2s ease-out;

$gallery-btn-bg:        #373f3f;
$text-color: $dark;
