
#branding {
	position: fixed !important;
	left: 50%;
	transform: translateX(-50%);
	margin: 0 auto;
	z-index: 0 !important;
}

#branding.adFull {
	width: 2000px;
	height: 1400px;
}

#branding.adFull ~ #vitalweb {
	position: relative;
	top: 226px;
	padding-bottom: 200px;
	width: 1060px !important;
	margin: auto;
}




