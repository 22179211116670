.btn {
  &-control {
    background-color: $gallery-btn-bg;
    color: #fff;

    font-size: 14px;
    font-weight: 400;
    line-height: 1;

    padding: 9px;
    margin: 0 4.5px;
    min-width: 40px;

    transition: color $default-transition, background-color $default-transition;

    i {
      font-size: 20px;
      vertical-align: text-bottom;

      &.icon-envelope {
        margin-right: 8px;
      }
    }

    &:focus,
    &:active {
      color: #fff;
    }

    &.active {
      color: $primary;

      &:active {
        color: #fff;
      }
    }

    &:hover {
      background-color: darken($gallery-btn-bg, 10%);
      &:not(.btn-cedulka) {
        color: #fff;
      }
    }

    &-inverted {
      background-color: #fff;
      color: $gallery-btn-bg;
      border: 1px solid $gallery-btn-bg;

      margin: 0 4.5px 0 30px;

      display: none;
      transition: display $default-transition;

      &:active,
      &:focus {
        color: $gallery-btn-bg;
      }

      &.shown {
        display: inline-block;
      }
    }
  }
}
