@mixin box-shadow-on-hover() {
  &:hover {
    box-shadow: 0 2px 10px rgba(58, 58, 58, 0.3);
  }
}

@mixin position($position, $coordinates: null) {
  position: $position;

  $offsets: (
          top: nth($coordinates, 1),
          right: nth($coordinates, 2),
          bottom: nth($coordinates, 3),
          left: nth($coordinates, 4)
  );

  @each $offset, $value in $offsets {
    @if _is-length($value) {
      #{$offset}: $value;
    }
  }
}

@mixin square($size) {
  @include size($size, $size);
}
