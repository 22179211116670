@import "variables";

@font-face {
  font-family: 'vitalweb';
  src:  url('#{$icomoon-font-path}/vitalweb.eot?2qojjo');
  src:  url('#{$icomoon-font-path}/vitalweb.eot?2qojjo#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/vitalweb.ttf?2qojjo') format('truetype'),
    url('#{$icomoon-font-path}/vitalweb.woff?2qojjo') format('woff'),
    url('#{$icomoon-font-path}/vitalweb.svg?2qojjo#vitalweb') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'vitalweb' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

.icon-menu-close {
  &:before {
    content: unicode($icon-menu-close); 
  }
}
.icon-menu {
  &:before {
    content: unicode($icon-menu); 
  }
}
.icon-search {
  &:before {
    content: unicode($icon-search); 
  }
}
.icon-share {
  &:before {
    content: unicode($icon-share); 
  }
}
.icon-fb {
  &:before {
    content: unicode($icon-fb); 
  }
}
.icon-envelope {
  &:before {
    content: unicode($icon-envelope); 
  }
}
.icon-close {
  &:before {
    content: unicode($icon-close); 
  }
}
.icon-arrow-right {
  &:before {
    content: unicode($icon-arrow-right);     
    color: #231f20;
  }
}
.icon-arrow-left {
  &:before {
    content: unicode($icon-arrow-left);     
    color: #231f20;
  }
}

