.article {
	&-title {
		color: $dark;
		font-size: 2.75rem;
		font-weight: 900;
		line-height: 1.18;
		letter-spacing: -0.22px;

		margin-bottom: 0.4773em;

		@include media-breakpoint-up(lg) {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}
	}

	&-main-img {
		display: block;
		max-width: 100%;

		img {
			max-width: 100%;
		}
	}

	&-list-small {
		height:115px;
		overflow: hidden;
		font-size: 0.95rem;
	}

	&-author {
		font-size: 0.875rem;
		color: $detail-light;
		line-height: 1.43;

		margin-bottom: 1.124rem;

		strong {
			color: $dark;
		}
	}

	&-content {
		padding-top: 25px;

		@include media-breakpoint-up(lg) {
			padding: 25px $grid-gutter-width 50px;
		}

		p {
			color: $dark;
			font-size: 1rem;
			line-height: 1.63;

			margin-bottom: 2em;
		}
	}

	&-perex {
		font-weight: 700;
	}

	&-gallery {
		margin-bottom: 2rem;

		img {
			width: 100%;
		}

		&-link {
			text-transform: uppercase;
			font-size: 1rem;
			font-weight: 700;
			line-height: 1.63;
			letter-spacing: 0.8px;
		}
	}

}

.dpsp-networks-btns-wrapper {
	list-style: none !important;
	padding: 0 !important;
	margin: 20px 0 0 0;

	-moz-transition: all 0.15s ease-in;
	-webkit-transition: all 0.15s ease-in;
	-o-transition: all 0.15s ease-in;
	transition: all 0.15s ease-in;
	h4 {
		font-size: 1.2rem;
	}
	li {
		float: left;
		margin-right: 30px;
		overflow: hidden;
	}
	.dpsp-network-btn {
		position: relative;
		display: block;
		text-decoration: none !important;
		text-transform: unset !important;
		height: 40px;
		min-width: 40px;
		font-family: 'Arial';
		font-weight: bold;
		font-size: 14px;
		line-height: 36px;
		vertical-align: middle;
		padding: 0 35px;
		border: 2px solid;
		max-height: 40px;
		width: 100%;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-moz-transition: all 0.15s ease-in;
		-webkit-transition: all 0.15s ease-in;
		-o-transition: all 0.15s ease-in;
		transition: all 0.15s ease-in;
		box-shadow: none;
		cursor: pointer;
		color: #fff;

	}
	.dpsp-facebook {
		background: #3a579a;
		border-color: #3a579a;
	}
	.dpsp-twitter {
		background: #00abf0;
		border-color: #00abf0;
	}
}

.image-source {
	color: #aaa;
	font-size: 12px;
}

.szn-btn-add-to-seznam {
	display: inline-block !important;
	background: transparent url(https://www.seznam.cz/media/img/add-to-seznam-button/pridat-na-seznam-hover.png) no-repeat center !important;
}

.szn-btn-add-to-seznam img {
	display: block !important;
}

.szn-btn-add-to-seznam img:hover {
	opacity: 0;
}

.noshadow:hover {
	box-shadow: none;
	-webkit-box-shadow: none;
}


.videocontainer {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	margin-bottom:15px;
	margin-top:15px;
	.video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

}

#videowidget {

	.card-body {
		padding: 1.0rem 0 1.2em;
	}

	H1.card-title {
		font-size: 23px;
		margin-bottom: 1rem;
		height:90px;
		overflow:hidden;

	}
	img {
		margin-bottom: 1rem;
	}
}

.video-title {
	margin-bottom:0px !important;
}

.with-video-label {
	position: relative;

	&:after {
		content: "video v článku";

		color: $dark;
		font-size: 0.625rem;
		text-transform: uppercase;
		letter-spacing: 1px;

		position: absolute;
		right: 0;
		top: -1.875em;
	}
}
