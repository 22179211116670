.card {
  margin-bottom: $grid-gutter-width;

  @include media-breakpoint-down(md) {
    height: auto !important;
  }

  &-img-top {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &-body {
    padding: $card-spacer-y $card-spacer-x $card-spacer-x;
  }

  &-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.27;
    letter-spacing: -0.15px;
  }

  &-text {
    margin-bottom: 1.875rem;
  }
}