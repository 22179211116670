.recommend {
  padding-top: 25px;

  h2 {
    font-size: 1.125rem;
    font-weight: 900;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: 0.54px;

    margin-bottom: 1em;
  }
}