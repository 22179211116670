.gallery {
  @include position(fixed, 0 0 0 0);
  width: 100%;

  &-holder {
    @include position(absolute, 0 0 0 0);
    width: 100%;
  }

  &-img {
    @include position(absolute, 0 0 0 0);

    background-color: #f5f5f5;

    height: 100%;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: calc(100% - 420px);
      min-height: 500px;
    }

    &-current {
      @include position(absolute, 0 0 0 0);
      @include square(100%);
      background: center / contain no-repeat;
    }

    &-desc {
      @include position(absolute, null null 80px 0);
      z-index: 3;

      font-size: 14px;
      line-height: 1.36;

      padding: 1em;
      width: 100%;

      background: rgba(#fff, 0.8);

      transition: opacity $default-transition;

      @include media-breakpoint-up(md) {
        bottom: 58px;
      }

      &.closed {
        opacity: 0;
      }

      &-holder {
        position: relative;

        .close-desc {
          @include position(absolute, -39px 1em null null);

          padding: 3px 8px;

          background: rgba(#fff, 0.8);
          color: $text-color;
          text-decoration: none !important;
        }
      }
    }

    &-controls {
      background: rgba(#fff, 0.5);

      @include position(absolute, auto auto 0 0);
      z-index: 4;

      text-align: center;
      font-size: 0;

      width: 100%;
      padding: 9px;

      @include media-breakpoint-up(md) {
        padding: 20px;
      }
    }

    &-full {
      width: 100%;
      transition: $transition-base;
    }
  }

  &-prev-arr,
  &-next-arr {
    position: absolute;
    top: 50%;
    z-index: 4;
    transform: translate(0, -50%);

    height: 63px;

    font-size: 0;
    color: rgba($text-color, 0.85);
    transition: color $default-transition;
    text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff, 0px -1px 0 #fff, 0 1px 0 #fff;

    text-decoration: none;

    &:hover {
      color: $text-color;
    }

    i {
      vertical-align: middle;
      font-size: 61px;
    }
  }

  &-prev-arr {
    left: 10px;

    @include media-breakpoint-up(md) {
      left: 30px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &-next-arr {
    right: 10px;

    @include media-breakpoint-up(md) {
      right: 30px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &-overlay {
    @include position(absolute, 0 0 0 0);
    @include square(100%);
    z-index: 2;

    opacity: 1;
    transition: opacity $default-transition;

    &.ng-hide {
      display: block !important;
      opacity: 0;
    }
  }

  &-info {
    @include position(absolute, 0 0 0 auto);

    transition: transform $default-transition;

    @include media-breakpoint-up(md) {
      background-color: #fff;
      width: 420px;
    }

    &-content {
      overflow-y: auto;

      @include position(absolute, 65px 0 0 0);

      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    section {
      padding: 20px;

      border-bottom: 1px solid $border-color;
    }

    h2 {
      font-size: 17px;
      font-weight: 700;
      line-height: 1.29;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      line-height: 1.36;
      margin-bottom: 10px;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    hr {
      width: calc(100% + 20px);
    }

    .rating {
      .star {
        @include square(14px);
        font-size: 14px;
        vertical-align: initial;
      }
    }

    .media-products {
      .row {
        margin-bottom: 8px;
      }

      span {
        font-size: 14px;
      }
    }

    &-interest {
      .col-sm-3 {
        margin-bottom: 5px;

        a {
          display: block;

          img {
            min-width: 100%;
            height: auto;
          }
        }
      }

      .sklikAd {
        background-color: #fff;
      }
    }

    &-hidden {
      transform: translate(420px, 0);
    }
  }

  &-close {
    padding: 10px;

    position: relative;
    z-index: 3;

    @include media-breakpoint-up(md) {
      padding: 18px;

      border-bottom: 1px solid $border-color;
      background-color: #fff;
    }

    a {
      font-size: 26px;
      color: $text-color;
      text-decoration: none;
      transition: color $default-transition;

      &:hover {
        color: $primary;
      }
    }
  }

  &-cedulka {
    position: absolute;
    top: 62.9%;
    left: 43.7%;

    &:after {
      content: "";
      display: block;
      @include size(23px, 39px);
      transform: translate(0, -39px);
    }

    object {
      @include size(23px, 39px);
    }

    &-bublina {
      background-color: #fff;
      border: 5px solid #eceded;

      padding: 24px 16px;
      width: 300px;
      min-height: 120px;

      position: absolute;
      top: 62.9%;
      left: 43.7%;
      z-index: 1;

      transform: translate(28px, 0);

      img {
        max-width: 80px;
        margin-right: 15px;
        float: left;
      }

      strong {
        display: block;
        margin-bottom: 7px;
        line-height: 1.21;
      }

      a {
        font-weight: 700;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-share-bubble {
    @include position(absolute, auto auto 59px 50%);

    @include media-breakpoint-up(md) {
      bottom: 70px;
    }

    background: $gallery-btn-bg;
    border-radius: 4px;

    opacity: 1;
    transition: $transition-base;

    &:after {
      content: "";
      display: block;
      border-width: 8px;
      border-color: $gallery-btn-bg transparent transparent transparent;
      border-style: solid;
      @include square(8px);

      position: absolute;
      bottom: -16px;
      left: 16px;
    }

    &.hide-bubble {
      opacity: 0;
      visibility: hidden;
      bottom: 30px;
    }
  }
}

.gallery-holder {
  .sk-double-bounce {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: calc(100% - 420px);
    }
  }
}

svg {
  &#cedulka {
    cursor: pointer;
  }
}
