.label {
  display: inline-block;
  margin-bottom: 0.5em;

  &-primary {
    color: $primary;
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.96px;
  }
}