a {
  transition: $transition-base;
}

img {
  max-width: 100%;
}

.no-underline {
  text-decoration: none !important;
}

.with-commecrcial-label {
  position: relative;

  &:after {
    content: "komerční prezentace";

    color: $dark;
    font-size: 0.625rem;
    text-transform: uppercase;
    letter-spacing: 1px;

    position: absolute;
    right: 0;
    top: -1.875em;
  }
}

html { position: relative; min-height: 100%; }
body {
  margin-bottom: 240px;
}
