.section {
	padding-top: 10px;
	padding-bottom: 10px;

	&-title {
		color: $dark;
		font-weight: 900;
		font-size: 2.5rem;
		line-height: 1.25;

		margin-bottom: 0.5em;
	}
}

@media (max-width: 768px) {
	#miniLeader {
		margin: auto;
		width: 300px;
		margin-top: 10px;
	}
}

.contpadding {
	background-color: $light;
}

@media (min-width: 576px) {
	.section {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.contpadding {
		padding: 0 30px;

	}
	.contpaddingtop {
		padding-top: 20px;
	}


}

