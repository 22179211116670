.search {
  display: none;

  &.active {
    display: block;
    width: calc(100% - 84px);

    @include media-breakpoint-up(md) {
      width: calc(100% - 202px);
    }
  }

  &-input {
    width: 100%;
  }
}