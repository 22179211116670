.news {
  &-item {
    background-color: $primary;

    padding: 52px 30px 30px;
    margin-top: 1.25em;
    height: 100%;

    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: 2em;
    }
  }

  &-title {
    color: $white;
    font-size: 1.06rem;
    font-weight: 700;
    line-height: 1.39;

    &:hover {
      color: $white;
    }
  }
  &-perex {
    color: $white;
    font-size: 0.8rem;
  }
  &-timestamp {
    text-align: center;

    position: absolute;
    top: -29px;

    font-size: 0.875rem;

    &-day {
      background-color: $dark;
      color: $white;

      width: 90px;
      line-height: 29px;
    }

    &-date {
      background-color: $white;
      color: $dark;

      width: 90px;
      line-height: 29px;
    }
  }
}
