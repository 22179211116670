.tags {
  font-size: 0;

  a {
    display: inline-block;
    padding: 4px 8px 3px;

    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0.72px;
    color: $detail-light;

    border: 1px solid $detail-light;

    &:hover {
      border-color: $purple;

      color: $purple;
      text-decoration: none;
    }

    + a {
      margin-left: 10px;
    }
  }

  &-mb {
    margin-bottom: 32px;
  }
}
