#vitalweb {
	background-color:#fff;
}

.ADSKYwrapper {
	//background: #eee; /* optional */
}
.ADSKYsticky {
	width: 300px; /* optional */
	margin:auto;
}
.ADSKYsticky-top {
	position:fixed;
	top: 0;
}
.ADSKYsticky-bottom {
	position: absolute;
	bottom: 0;
}

#ssp-zone-111580-footer.adFull {
	margin-top:15px;
}
