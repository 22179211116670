.media {
  transition: $transition-base;
  @include box-shadow-on-hover;
  width: 100%;

  &-img {
    display: block;
    margin-right: 23px;
  }

  a {
    &:hover {
      color: $dark;
    }
  }

  &-img {
    max-width: 145px;
    height: 100%;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      max-width: 220px;

      img {
        height: 100%;
      }
    }
  }

  &-title {
    font-size: 0.9375rem;
    font-weight: 700;
    line-height: 1.41;
    letter-spacing: -0.09px;

    @include media-breakpoint-up(lg) {
      font-size: 1.0625rem;
    }
  }

  &-body {
    padding-top: 14px;
    padding-bottom: 11px;
    padding-right: 11px;
  }

  &-sm {
    .media-img {
      max-width: 145px;
      height: 100%;
      overflow: hidden;
    }

    .media-title {
      font-size: 0.9375rem;
      font-weight: 700;
      line-height: 1.27;
      margin: 0;
    }

    .media-body {
      padding-top: 11px;
    }
  }

  &-recommend {
    .media-body {
      padding-top: 11px;
    }

    .media-img {
      max-width: 145px;
      height: 100%;
      overflow: hidden;

      img {
        height: auto;
      }
    }

    .media-title {
      font-size: 0.9375em;
    }

    &:hover {
      box-shadow: none;
    }
  }

  + .media,
  + .card,
  + .load-holder {
    margin-top: $grid-gutter-width;
  }

  &-gallery {
    background-color: $white;
    padding: $grid-gutter-width / 2;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }

    &-holder {
      @include make-row;
      width: calc(100% + #{$grid-gutter-width});
    }

    img {
      width: 100%;
      margin-bottom: $grid-gutter-width / 2;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }
}
