footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 170px;


	padding-top: 40px;

	background-color: #383d3f;
	font-weight: 300;
	font-size: 13px;
	line-height: 1.33;
	color: #eceded;

	.site-links{
		margin-bottom:20px;
	}
	.separator {
		margin: 0 11px;
	}
}

