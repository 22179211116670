.category {
  padding-top: 0;
  padding-bottom: 0;

  a {
    color: $white;

    font-size: 0.6375rem;
    text-transform: uppercase;
    letter-spacing: 0.75px;

    &:hover {
      background-color: $primary;
      color: $dark;
    }
  }
}
