
.company-header {
	position: relative;
	background-color: #fff;
	margin-bottom: 80px;
}

@media (min-width: 768px) {
	.company-header {
		margin-bottom: 40px;
	}
}

.company-header-img {
	position: relative;
}

@media (min-width: 768px) {
	.company-header-img {

		//height: 261px;
	}
}

.company-header-img > img {
	//height: 200px;
	width: auto;
}

@media (min-width: 768px) {
	.company-header-img > img {
		//height: 100%;
	}
}

@media (min-width: 768px) {
	.company-header-img:after {
		content: "";
		display: block;
		background-image: -webkit-linear-gradient(top, transparent 0%, #fff 100%);
		background-image: -o-linear-gradient(top, transparent 0%, #fff 100%);
		background-image: linear-gradient(to bottom, transparent 0%, #fff 100%);
		background-repeat: repeat-x;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#FFFFFFFF', GradientType=0);
		width: 100%;
		height: 145px;
		position: absolute;
		top: auto;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.company-header-brand {
	background: #fff;
	display: block;
	border: 1px solid #fcfcfc;
	overflow: hidden;
	text-align: center;
	line-height: 140px;
	width: 140px;
	height: 120px;
	position: relative;
	top: -70px;
	right: auto;
	bottom: auto;
	left: auto;
	margin: 0 auto;
	-webkit-box-shadow: 0 0 7px rgba(169, 169, 169, 0.7);
	box-shadow: 0 0 7px rgba(169, 169, 169, 0.7);
}

@media (min-width: 768px) {
	.company-header-brand {
		bottom: -22px;
		position: absolute;
		top: auto;
		right: auto;
		bottom: 0;
		left: 20px;
		z-index: 3;
	}
}

.company-header-brand img {
	max-width: 100%;
	max-height: 100%;
	vertical-align: text-bottom;
	position: absolute;
	top: 50%;
	right: auto;
	bottom: auto;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
	.company-header-brand img {
		height: auto;
	}
}

.company-header-name {
	width: 100%;
	text-align: center;
	margin: -50px 0 20px;
}

@media (min-width: 768px) {
	.company-header-name {
		text-align: left;
		bottom: -23px;
		padding-left: 186px;
		margin: 0;
		z-index: 2;
		position: absolute;
		top: auto;
		right: auto;
		bottom: 0;
		left: 0;
	}
}

@media (min-width: 992px) {
	.company-header-name {
		bottom: 13px;
	}
}

.company-header-name h1 {
	font-size: 24px;
	line-height: 1;
	font-weight: 700;
	margin: 0;
}

@media (min-width: 992px) {
	.company-header-name h1 {
		float: left;
	}
}

.company-header-name h1 a {
	color: #373c3e;
}

.company-header-rating {
	margin-top: 15px;
}

@media (min-width: 992px) {
	.company-header-rating {
		float: right;
		padding-right: 20px;
		margin-top: 0;
	}
}

.company-header-rating a {
	text-decoration: underline;
}

.company-header-rating a:hover {
	text-decoration: none;
}

@media (min-width: 768px) {
	.company-header-rating a {
		margin-right: 18px;
	}
}

.company-header-text {
	background-image: -webkit-linear-gradient(top, #eceded 0%, #fff 100%);
	background-image: -o-linear-gradient(top, #eceded 0%, #fff 100%);
	background-image: linear-gradient(to bottom, #eceded 0%, #fff 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFECEDED', endColorstr='#FFFFFFFF', GradientType=0);
	padding: 36px 0 30px;
	text-align: center;
}

@media (min-width: 768px) {
	.company-header-text {
		background: none;
		text-align: left;
		padding: 56px 20px 30px;
	}
}

.company-header-text h2 {
	font-size: 16px;
	margin-bottom: 28px;
	padding: 0 20px;
	font-weight: 400;
	line-height: 1.33;
}

@media (min-width: 768px) {
	.company-header-text h2 {
		font-size: 18px;
		margin-bottom: 10px;
		padding: 0;
	}
}

.company-header-text p {
	font-size: 14px;
	line-height: 1.86;
	padding: 0 20px;
}

@media (min-width: 768px) {
	.company-header-text p {
		line-height: 1.5;
		padding: 0;
	}
}

.company-header-text p a {
	text-decoration: underline;
}

.company-header-text p a:hover {
	text-decoration: none;
}

.company-header.can-edit .profile-edit {
	display: none;
	border: 1px solid #373c3e;
	background: #d9d9d9;
	position: absolute;
	right: 0;
	top: 0;
	padding: 4px 7px;
}

.company-header.can-edit .profile-edit a {
	color: #373c3e;
}

.company-header.can-edit:hover {
	-webkit-box-shadow: 0 0 4px rgba(51, 51, 51, 0.7);
	box-shadow: 0 0 4px rgba(51, 51, 51, 0.7);
}

.company-header.can-edit:hover .profile-edit {
	display: inline-block;
}

.company-backlink {
	margin-bottom: 5px;

}

.backlink {
	text-align: right;
	color: #f7941e;
	text-transform: uppercase;
}

.backlink a {
	font-size: 14px;
	margin-left: 6px;
}

.backlink i {
	font-size: 11px;
}

.user {

	.aside-right{
		margin-top:40px;
	}


	@media (min-width: 992px) {
		.main-left {
			float: left;
			width: 730px;
		}
		.aside-right {
			width:380px;
			float: left;
			margin-left:30px;
			margin-top:0px;
		}
		.box {
			margin: 0 0 25px 0;

		}
	}
	.main-left .spinner {
		display: none;
		height: 46px;
	}
	#contact-company {
		width:100%;
	}

	.box {
		background-color: #fff;
		margin: 0;
	}


	.box-content {
		padding: 20px;
	}
	.box h3 {
		margin-bottom: 15px;
	}
	.box h3:first-child {
		text-transform: uppercase;
	}
	.box-interesting img {
		margin-bottom: 12px;
	}
	.box-interesting-heading a {
		color: #373c3e;
	}
	.box-interesting-p p {
		line-height: 1.5;
	}
	.box-interesting-p a {
		display: inline-block;
		margin-bottom: 10px;
		text-decoration: underline;
	}
	.box-interesting-p a:hover {
		text-decoration: none;
	}
	.box-interesting-p a.top-margin {
		margin-top: 5px;
	}
	.box-interesting-contact {
		color: #f7941e;
	}
	.box-interesting-contact b {
		display: inline-block;
		margin-bottom: 5px;
	}
	.box-interesting-contact a {
		text-decoration: underline;
	}
	.box-interesting-contact a:hover {
		text-decoration: none;
	}
	.container {
		padding-bottom: 60px;
	}
}
